<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body">
          <div class="vx-row no-gutter justify-center items-center">
            <vs-progress
              v-if="loading"
              indeterminate
              color="primary"
            ></vs-progress>
          </div>
          <div>
            <form-wizard
              color="rgba(var(--vs-primary), 1)"
              errorColor="rgba(var(--vs-danger), 1)"
              :title="null"
              :subtitle="null"
              finishButtonText="Start System"
            >
              <tab-content
                title="Activation"
                class="mb-5"
                icon="feather icon-shield"
                :before-change="validateStep1"
              >
                <form data-vv-scope="step-1">
                  <div class="vx-row">
                    <div class="ml-4 mr-4 text-center">
                      <p>
                        A valid
                        <router-link :to="`/generate/${machineID}`"
                          >license key</router-link
                        >
                        from our organization is required to start this system.
                        Your installation ID is listed below. Please contact our
                        support team <b>+254 710 710 037</b> with the
                        <b>Installation ID</b> below to aquire a new license key
                        then copy and paste below.
                      </p>
                    </div>
                    <div class="vx-col md:w-1/2 w-full">
                      <vs-textarea
                        disabled="true"
                        v-model="machineID"
                        label="Installation ID"
                        class="md:mt-10 mt-6 mb-0"
                        rows="3"
                        name="installation_id"
                        v-validate="'required|min:20'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-1.installation_id')
                      }}</span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full">
                      <vs-textarea
                        v-model="license"
                        label="License Key"
                        class="md:mt-10 mt-6 mb-0"
                        rows="3"
                        name="license"
                        v-validate="'required|min:162'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-1.license')
                      }}</span>
                    </div>
                    <div class="vx-col w-full ml-4 mt-5">
                      <div class="vx-row">
                        <vs-checkbox v-model="allow_backups" />
                        <span>
                          Allow external backups & Multi-store database</span
                        >
                      </div>
                      <vs-chip class="text-sm ml-4 mr-2 mb-1" color="primary"
                        >Recommended</vs-chip
                      >
                      <span class="text-sm mr-4"
                        >Secure cloud periodic backups of your data encrypted at
                        our database servers</span
                      >
                    </div>
                    <div v-if="allow_backups" class="vx-row w-full ml-4 mr-4">
                      <vs-divider>Database</vs-divider>
                      <div class="vx-col w-full mt-5">
                        <vs-select
                          v-model="create_db"
                          class="w-full select-large"
                          label="Database Option"
                          name="database"
                          v-validate="'required'"
                        >
                          <vs-select-item
                            :key="index"
                            :value="item.value"
                            :text="item.label"
                            v-for="(item, index) in dbOptions"
                            class="w-full"
                          />
                        </vs-select>
                        <span class="text-sm">
                          You have two options to create a new database or link
                          with existing database</span
                        >
                        <br />
                        <span class="text-danger">{{
                          errors.first('step-1.database')
                        }}</span>
                      </div>
                      <div v-if="!create_db" class="vx-col w-full mt-5">
                        <vs-input
                          label="Database ID"
                          v-model="link_db"
                          class="w-full"
                          name="remote_db"
                          v-validate="'required'"
                        />
                        <span class="text-sm">
                          Enter external installation id to link this database
                          with the external database</span
                        >
                        <br />
                        <span class="text-danger">{{
                          errors.first('step-1.remote_db')
                        }}</span>
                      </div>
                      <div class="vx-col md:w-1/3 w-full mt-5">
                        <vs-select
                          v-model="protocol"
                          class="w-full select-large"
                          label="Protocol"
                        >
                          <vs-select-item
                            :key="index"
                            :value="item"
                            :text="item"
                            v-for="(item, index) in protocolOptions"
                            class="w-full"
                          />
                        </vs-select>
                      </div>
                      <div class="vx-col md:w-1/3 w-full mt-5">
                        <vs-input
                          label="Remote DB Url"
                          v-model="remote_url"
                          class="w-full"
                          name="remote_url"
                          v-validate="{ required: true }"
                        />
                        <span class="text-danger">{{
                          errors.first('step-1.remote_url')
                        }}</span>
                      </div>
                      <div class="vx-col md:w-1/3 w-full mt-5">
                        <vs-input
                          label="Remote DB Port"
                          v-model="remote_port"
                          class="w-full"
                          name="remote_port"
                          v-validate="'numeric'"
                        />
                        <span class="text-danger">{{
                          errors.first('step-1.remote_port')
                        }}</span>
                      </div>
                      <div class="vx-col md:w-1/2 w-full mt-5">
                        <vs-input
                          label="Remote DB Username"
                          v-model="remote_username"
                          class="w-full"
                          name="remote_username"
                          v-validate="'alpha'"
                        />
                        <span class="text-danger">{{
                          errors.first('step-1.remote_username')
                        }}</span>
                      </div>
                      <div class="vx-col md:w-1/2 w-full mt-5">
                        <vs-input
                          type="password"
                          label="Remote DB Password"
                          v-model="remote_password"
                          class="w-full"
                          name="remote_password"
                        />
                        <span class="text-danger">{{
                          errors.first('step-1.remote_password')
                        }}</span>
                      </div>
                    </div>
                  </div>
                </form>
              </tab-content>

              <!-- tab 2 content -->
              <tab-content
                title="Business Information"
                class="mb-5"
                icon="feather icon-briefcase"
                :before-change="validateStep2"
              >
                <!-- tab 1 content -->
                <form data-vv-scope="step-2">
                  <div class="vx-row">
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="Business Name"
                        v-model="business_name"
                        class="w-full"
                        name="business_name"
                        v-validate="'required'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-2.business_name')
                      }}</span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="Main Phone"
                        v-model="main_phone"
                        class="w-full"
                        name="main_phone"
                        v-validate="'required|numeric|max:10|min:10'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-2.main_phone')
                      }}</span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="Postal Address"
                        v-model="postal_address"
                        class="w-full"
                        name="address"
                        v-validate="'required'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-2.address')
                      }}</span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="Postal City"
                        v-model="postal_city"
                        class="w-full"
                        name="city"
                        v-validate="'required'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-2.city')
                      }}</span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-select
                        @input="updateCurrency"
                        v-model="country"
                        class="w-full select-large"
                        label="Country"
                        name="country"
                        v-validate="'required'"
                      >
                        <vs-select-item
                          :key="index"
                          :value="item"
                          :text="item.countryName"
                          v-for="(item, index) in countryOptions"
                          class="w-full"
                        />
                      </vs-select>
                      <span class="text-danger">{{
                        errors.first('step-2.country')
                      }}</span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="Currency"
                        disabled="true"
                        v-model="currency"
                        class="w-full"
                        name="currency"
                        v-validate="'required'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-2.currency')
                      }}</span>
                    </div>
                    <vs-divider>Tax</vs-divider>
                    <div class="vx-col md:w-1/2 w-full">
                      <vs-input
                        label="Tax PIN"
                        v-model="tax_pin"
                        class="w-full"
                        name="pin"
                      />
                    </div>
                    <div class="vx-col md:w-1/2 w-full">
                      <vs-input
                        label="VAT Reg"
                        v-model="vat_reg"
                        class="w-full"
                        name="vat_reg"
                      />
                    </div>
                  </div>
                </form>
              </tab-content>

              <!-- tab 3 content -->
              <tab-content
                title="Admin"
                class="mb-5"
                icon="feather icon-user"
                :before-change="validateStep3"
              >
                <form data-vv-scope="step-3">
                  <div class="vx-row" v-if="!admin_available">
                    <div class="vx-col w-full mt-5">
                      <vs-button
                        @click="admin_available = true"
                        class="flex flex-wrap mr-4 center"
                        >Skip Admin Registration</vs-button
                      >
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="Name"
                        v-model="name"
                        class="w-full"
                        name="name"
                        v-validate="'required|min:4'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-3.name')
                      }}</span>
                    </div>

                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        label="Phone"
                        v-model="phone"
                        class="w-full"
                        name="phone"
                        v-validate="'required|numeric|max:10|min:10'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-3.phone')
                      }}</span>
                    </div>

                    <vs-divider>Other</vs-divider>
                    <div class="vx-col md:w-1/2 w-full">
                      <vs-input
                        label="Username"
                        v-model="username"
                        class="w-full"
                        name="username"
                        v-validate="'required|alpha|min:3'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-3.username')
                      }}</span>
                    </div>

                    <div class="vx-col md:w-1/2 w-full">
                      <vs-input
                        label="Email"
                        v-model="email"
                        class="w-full"
                        name="email"
                        v-validate="'email'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-3.email')
                      }}</span>
                    </div>

                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        type="password"
                        label="Password"
                        v-model="password"
                        class="w-full"
                        name="password"
                        ref="password"
                        v-validate="'required|min:4'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-3.password')
                      }}</span>
                    </div>

                    <div class="vx-col md:w-1/2 w-full mt-5">
                      <vs-input
                        type="password"
                        label="Confirm Password"
                        v-model="confirm_password"
                        class="w-full"
                        name="confirm_password"
                        v-validate="'required|confirmed:password'"
                      />
                      <span class="text-danger">{{
                        errors.first('step-3.confirm_password')
                      }}</span>
                    </div>
                  </div>
                  <div class="vx-row text-center center-div p-4" v-else>
                    <feather-icon
                      icon="CheckIcon"
                      class="text-success center p-3 inline-flex rounded-full"
                    >
                    </feather-icon>
                    An admin user is already registered. Please contact
                    {{ admin.name }} to register other accounts on this system.
                    Continue with the installation below.
                  </div>
                </form>
              </tab-content>

              <!-- tab 4 content -->
              <tab-content
                title="Finish"
                class="mb-5"
                icon="feather icon-arrow-right"
                :before-change="validateStep4"
              >
                <form data-vv-scope="step-4">
                  <div class="vx-row">
                    <template v-if="!branch">
                      <div class="vx-col md:w-1/2 w-full mt-5">
                        <vs-input
                          label="Main Branch Name"
                          v-model="branch_name"
                          class="w-full"
                          name="branch_name"
                        />
                      </div>
                      <div class="vx-col md:w-1/2 w-full mt-5">
                        <vs-input
                          label="Branch Phone"
                          v-model="branch_phone"
                          class="w-full"
                          name="branch_phone"
                          v-validate="'numeric|max:10|min:10'"
                        />
                        <span class="text-danger">{{
                          errors.first('step-4.branch_phone')
                        }}</span>
                      </div>
                    </template>
                    <template v-if="!payment">
                      <vs-divider>Payment</vs-divider>
                      <div class="vx-col md:w-1/2 w-full">
                        <vs-input
                          label="Main Payment Option"
                          v-model="payment_option"
                          class="w-full"
                          name="payment_option"
                        />
                      </div>
                      <div class="vx-col md:w-1/2 w-full mb-5">
                        <vs-input
                          label="Secondary Payment Option"
                          v-model="payment_option2"
                          class="w-full"
                          name="secondary_payment_option"
                        />
                      </div>
                    </template>
                  </div>
                  <div
                    class="text-center center-div p-4"
                    v-if="payment && branch"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="text-success center p-3 inline-flex rounded-full"
                    >
                    </feather-icon
                    ><br />
                    Hurray, some options had already been set here
                  </div>
                </form>
              </tab-content>
            </form-wizard>

            <div class="flex flex-wrap justify-start pt-2 pb-1">
              <vx-tooltip
                class="cursor-pointer navbar-fuzzy-search ml-4 mt-2"
                :text="db.s"
                position="bottom"
              >
                <feather-icon :class="`text-${db.m}`" icon="DatabaseIcon" />
                <feather-icon
                  :class="`text-${db.m}`"
                  :icon="`${type(db.s)}Icon`"
                />
              </vx-tooltip>
              <span class="ml-2 mt-1">{{ db.s }}</span>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import crypto from 'crypto';
import { v4 as uuidv4 } from 'uuid';

import countries from './countries.json';

import { Validator } from 'vee-validate';
const dict = {
  custom: {
    business_name: {
      required: 'A business name is required',
    },
    main_phone: {
      required: 'A phone number is required',
      numeric: 'Phone numbers may only be numeric',
      max: 'A valid 10 digit phone number is required',
      min: 'A valid 10 digit phone number is required',
    },
    address: {
      required: 'A postal address is required',
    },
    city: {
      required: 'A valid postal city is required',
    },
    country: {
      required: 'Select a country',
    },
    currency: {
      required: 'A valid currency code is required',
    },
    license: {
      required: 'A valid license key is required to activate this program',
      min: 'The license key should be at least 162 characters long',
    },
    name: {
      required: 'A valid name is required',
    },
    phone: {
      required: 'A phone number is required',
      numeric: 'Phone numbers may only be numeric',
      max: 'A valid 10 digit phone number is required',
      min: 'A valid 10 digit phone number is required',
    },
    username: {
      required: 'A valid username is required',
      alpha: 'Usernames should only contain alphabetic characters',
      min: 'Usernames should be at least 3 characters',
    },
    email: {
      email: 'A valid email is expected',
    },
    password: {
      required: 'A password is required',
      min: 'Passwords should be at least 4 characters',
    },
    confirm_password: {
      required: 'Confirm your password',
      confirmed: 'Passwords do not match',
    },
    branch_name: {
      required: 'The default branch is required',
    },
    branch_phone: {
      required: 'The Branch phone number is required',
      numeric: 'Phone numbers may only be numeric',
      max: 'A valid 10 digit phone number is required',
      min: 'A valid 10 digit phone number is required',
    },
    payment_option: {
      required: 'The default payment option is required',
    },
  },
};

import idb from '@/db/idb';
import p from '@/http/pouch/index.js';
import PouchDB from 'pouchdb-browser';
import FeatherIcon from '../../../../components/FeatherIcon.vue';

Validator.localize('en', dict);

export default {
  components: {
    FormWizard,
    TabContent,
    FeatherIcon,
  },
  data() {
    return {
      loading: false,
      machineID: 'Getting Installation ID ... ',
      license: '',
      current_tab: 0,
      //start
      business_name: '',
      main_phone: '',
      postal_address: '',
      postal_city: '',
      country: {},
      c: '',
      tax_pin: '',
      vat_reg: '',
      // admin
      admin_available: false,
      admin: {},
      username: '',
      password: '',
      confirm_password: '',
      name: '',
      email: '',
      phone: '',
      // other
      branch_name: '',
      branch_phone: '',
      payment_option: '',
      payment_option2: '',
      countryOptions: countries,
      publicKey: '',
      //
      allow_backups: true,
      create_db: true,
      link_db: '',
      dbOptions: [
        {
          label: 'Create new database for this store',
          value: true,
        },
        {
          label: 'Link existing database',
          value: false,
        },
      ],
      protocol: 'https',
      protocolOptions: ['https', 'http'],
      remote_url: 'couch1.db.cw.co.ke',
      remote_port: 443,
      remote_username: null,
      remote_password: null,
    };
  },
  pouch: {
    branchData() {
      return {
        database: 'express',
        selector: { type: 'branch' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
    POData() {
      return {
        database: 'express',
        selector: { type: 'PO' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
    activationData() {
      return {
        database: 'express',
        selector: { _id: this.machineID },
        first: true,
      };
    },
    businessData() {
      return {
        database: 'express',
        selector: { _id: 'business' },
        first: true,
      };
    },
  },
  watch: {
    businessData(val) {
      console.log('val', val);
      if (val && 'data' in val) {
        this.business_name = val.data.business_name;
        this.main_phone = val.data.main_phone;
        this.postal_address = val.data.postal_address;
        this.postal_city = val.data.postal_city;
        this.tax_pin = val.data.tax_pin;
        this.vat_reg = val.data.vat_reg;
        this.country = val.data.country;
        this.currency = val.data.currency;
      }
    },
  },
  computed: {
    // Sync progress data
    db() {
      return this.$store.state.db;
    },
    branch() {
      var b = this.branchData ? this.branchData : [];
      return b.length > 0;
    },
    payment() {
      var p = this.POData ? this.POData : [];
      return p.length > 0;
    },
    currency: {
      get() {
        return this.c;
      },
      set(d) {
        this.c = d;
      },
    },
    getDB() {
      return `${this.protocol}://${
        this.remote_username && this.remote_password
          ? `${this.remote_username}:${this.remote_password}@`
          : ''
      }${this.remote_url}${this.remote_port ? `:${this.remote_port}` : ''}`;
    },
    primaryColor: {
      get() {
        return this.$store.state.themePrimaryColor;
      },
      set(val) {
        this.$store.commit('UPDATE_PRIMARY_COLOR', val);
      },
    },
  },
  methods: {
    type(str) {
      var a = str.split(' ');
      var v = false;
      a.map((t) => {
        switch (t) {
          case 'download':
            v = 'Download';
            break;
          case 'upload':
            v = 'Upload';
            break;
        }
      });
      return v;
    },
    updateCurrency() {
      this.currency = this.country.currencyCode;
    },
    async getMachineID() {
      var k = await idb.getRecord('installationID');
      if (k) {
        this.machineID = k.data;
      } else {
        var uuid = uuidv4();
        idb.saveRecord({
          type: 'installationID',
          data: uuid,
        });
        this.machineID = uuid;
      }
    },
    //
    decrypt(a) {
      try {
        let key = Buffer.from(a[0], 'hex');
        let iv = Buffer.from(a[1], 'hex');
        let en = Buffer.from(a[2], 'hex');
        let decipher = crypto.createDecipheriv(
          'aes-256-cbc',
          Buffer.from(key),
          iv,
        );
        let de = decipher.update(en);
        de = Buffer.concat([de, decipher.final()]);
        return JSON.parse(atob(de.toString()));
      } catch (err) {
        return false;
      }
    },
    verify(d) {
      try {
        const verify = crypto.createVerify('RSA-SHA256');
        verify.update(new Buffer.from(JSON.stringify(d.data)));

        return verify.verify(this.publicKey, d.signature, 'hex');
      } catch (e) {
        console.log('Signature verification error', e);
        return false;
      }
    },
    async syncDB() {
      if (this.allow_backups) {
        let db = this.getDB;
        if (this.create_db) {
          db = `${db}/db-${this.machineID}`;
        } else {
          db = `${db}/${this.link_db}`;
        }
        await idb.saveRecord({
          type: 'database',
          db: db,
          allow_backups: this.allow_backups,
          create_db: this.create_db,
          link_db: this.link_db,
          remote_url: this.remote_url,
          remote_username: this.remote_username,
          remote_password: this.remote_password,
          protocol: this.protocol,
          port: this.remote_port,
        });
        p.init(this.$pouch);
        this.$vs.notify({
          position: 'top-center',
          color: 'warning',
          title: `Database Sync Started`,
          text: "I'll try and contact the database and sync data whenever possible",
        });
      } else {
        await idb.saveRecord({
          type: 'database',
          db: null,
          allow_backups: this.allow_backups,
        });
      }
    },
    showError(message) {
      this.$vs.notify({
        title: 'Error',
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
        time: 13000,
      });
    },
    validateStep1() {
      var a = this.license.split(':');
      this.loading = true;
      return new Promise(async (resolve, reject) => {
        if (a.length != 3) {
          this.loading = false;
          this.showError(
            'Invalid License Key Provided. License keys should match our signature. Please contact our Support Team.',
          );
          reject('ERROR');
        } else {
          try {
            var c_pdb = false;
            if (this.allow_backups) {
              let db = this.getDB;
              if (this.create_db) {
                db = `${db}/db-${this.machineID}`;
              } else {
                db = `${db}/${this.link_db}`;
              }
              var pdb = new PouchDB(db);
              c_pdb = await pdb.info();
            } else {
              c_pdb = true;
            }
            if (c_pdb) {
              this.$validator.validateAll('step-1').then(async (result) => {
                this.loading = false;
                if (result) {
                  var c = false;
                  var d = this.decrypt(a);
                  if (d != false) {
                    var chs = this.verify(d);
                    if (chs) {
                      var data = d.data;
                      if (data.machineID === this.machineID) {
                        await idb.saveRecord({
                          type: 'license',
                          installationID: data.machineID,
                          license: this.license,
                          expiry: data.expiry,
                          start: data.start,
                          date: Date.now(),
                        });
                        // this.business_name =
                        //   d.data.metadata.business_name || '';
                        // this.main_phone = d.data.metadata.main_phone || '';
                        // this.postal_address =
                        //   d.data.metadata.postal_address || '';
                        // this.postal_city = d.data.metadata.postal_city || '';
                        // this.country = d.data.metadata.country || '';
                        // this.currency = d.data.metadata.currency || '';

                        if (window.ipcRenderer) {
                          window.ipcRenderer.send(
                            'welcome',
                            `Thank you for choosing Express POS. \nYour license key has been successfully verified and will expire on ${this.$moment(
                              data.expiry,
                            ).format('dddd, MMMM Do YYYY')}`,
                          );
                        }

                        this.$vs.notify({
                          title: 'Cheers',
                          text: `Thank you for choosing Express POS. Your license key has been successfully verified
                          and will expire on ${this.$moment(data.expiry).format(
                            'dddd, MMMM Do YYYY',
                          )}. ${
                            window.ipcRenderer
                              ? 'You have a valid installation, we have sent a print test to your printer. Please check your printer.'
                              : 'You are using our web version, currently we do not have printer support but we are working on bringing this onboard.'
                          }`,
                          iconPack: 'feather',
                          icon: 'icon-check-circle',
                          color: 'success',
                          time: 100000,
                        });
                        await this.syncDB();

                        await idb.saveRecord({
                          type: 'current_tab',
                          tab: 1,
                        });

                        resolve(true);
                      } else {
                        this.showError(
                          'This license belongs to another PC. Request one for your PC.',
                        );
                        reject('ERROR');
                      }
                    } else {
                      this.showError('License contains an invalid signature');
                      reject('ERROR');
                    }
                  } else {
                    this.showError('Invalid License Provided');
                    reject('ERROR');
                  }
                } else {
                  reject('correct all values');
                }
              });
            } else {
              throw 'Unknown Error';
            }
          } catch (e) {
            this.showError(
              `Failed to connect to database. ERROR: ${e.reason || e}`,
            );
            reject(e);
          }
        }
      });
    },
    async validateStep2() {
      if (!this.db.idle) {
        this.$vs.notify({
          title: 'Opps :)',
          text: 'Please wait for database to finish syncing',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'warning',
          time: 10000,
        });
      }

      await idb.saveRecord({
        type: 'current_tab',
        tab: 1,
      });

      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-2').then(async (result) => {
          this.loading = false;
          if (result) {
            console.log(this.country);

            await this.$store.dispatch('updateBusinessSettings', {
              type: 'general',
              business_name: this.business_name,
              main_phone: this.main_phone,
              postal_address: this.postal_address,
              postal_city: this.postal_city,
              tax_pin: this.tax_pin,
              vat_reg: this.vat_reg,
              country: this.country,
              currency: this.currency,
              theme: {
                primaryColor: this.primaryColor,
              },
            });

            await this.$store.dispatch('updateSettingBulk', [
              {
                key: 'print_sale_receipt',
                value: true,
              },
              {
                key: 'print_sale_receipt_footer',
                value: '',
              },
              {
                key: 'sale_redirect_to_receipt',
                value: false,
              },
            ]);

            await idb.saveRecord({
              type: 'business',
              business_name: this.business_name,
              main_phone: this.main_phone,
              postal_address: this.postal_address,
              postal_city: this.postal_city,
              country: this.country,
              currency: this.currency,
              vat_reg: this.vat_reg,
              tax_pin: this.tax_pin,
            });
            this.$pouch
              .find(
                {
                  selector: {
                    role: 'admin',
                  },
                },
                'express',
              )
              .then(async (res) => {
                this.admin_available = res.docs.length > 0 || false;
                if (res.docs.length > 0) {
                  this.admin = res.docs[0];
                  this.$vs.notify({
                    title: 'Opps :)',
                    text: 'There is an Admin user already registered in this system',
                    iconPack: 'feather',
                    icon: 'icon-check-circle',
                    color: 'warning',
                    time: 13000,
                  });
                  await idb.saveRecord({
                    type: 'current_tab',
                    tab: 3,
                  });
                  return;
                }
                await idb.saveRecord({
                  type: 'current_tab',
                  tab: 2,
                });
                return;
              });
            resolve(true);
          } else {
            reject('correct all values');
          }
        });
      });
    },
    async validateStep3() {
      await idb.saveRecord({
        type: 'current_tab',
        tab: 2,
      });

      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-3').then((result) => {
          this.loading = false;
          if (result) {
            if (!this.admin_available) {
              const obj = {
                username: this.username,
                password: this.password,
                name: this.name,
                email: this.email,
                phone: this.phone,
                role: 'admin',
              };

              this.$store
                .dispatch('users/addUser', obj)
                .then((r) => {
                  this.$vs.notify({
                    color: 'success',
                    title: 'User Registered Successfully',
                    text: 'You have successfully registered a user',
                  });
                  resolve(true);
                  this.admin_available = true;
                  this.admin = r;
                })
                .catch((err) => {
                  console.error(err);
                  this.$vs.notify({
                    color: 'danger',
                    title: 'Error',
                    text: err,
                  });
                });
            } else {
              resolve(true);
            }
          } else {
            reject('correct all values');
          }
        });
      });
    },
    async validateStep4() {
      await idb.saveRecord({
        type: 'current_tab',
        tab: 3,
      });
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-4').then(async (result) => {
          this.loading = false;
          if (result) {
            if (this.branch && !this.admin_available) {
              const branch = {
                branch_name: this.branch_name,
                branch_phone: this.branch_phone,
              };

              this.$store
                .dispatch('branches/addBranch', branch)
                .then(async () => {
                  this.$vs.notify({
                    color: 'success',
                    title: `${branch.branch_name} Branch Added Successfully`,
                    text: 'You have successfully added a new branch',
                  });
                  await idb.saveRecord({
                    type: 'branch',
                    branch_name: this.branch_name,
                    branch_phone: this.branch_phone,
                  });

                  if (this.payment && !this.admin_available) {
                    const payment = {
                      payment_option: this.payment_option,
                      status: true,
                    };

                    this.savePaymentOption(payment);
                    await idb.saveRecord({
                      type: 'payment',
                      payment_option: this.payment_option,
                    });
                    if (this.payment_option2) {
                      const payment = {
                        payment_option: this.payment_option2,
                        status: true,
                      };

                      this.savePaymentOption(payment);
                      await idb.saveRecord({
                        type: 'payment',
                        payment_option: this.payment_option,
                        payment_option2: this.payment_option2,
                      });
                    }
                  }
                  this.finish();
                  resolve(true);
                })
                .catch((err) => {
                  console.error(err);
                  this.$vs.notify({
                    color: 'danger',
                    title: 'Error',
                    text: err,
                  });
                });
            } else {
              this.finish();
            }
          } else {
            reject('correct all values');
          }
        });
      });
    },
    async finish() {
      await idb.saveRecord({
        type: 'installation',
        complete: true,
      });

      var l = await this.getRecord('license');

      await idb.saveRecord({
        type: 'activation',
        active: true,
        license: this.license,
        start: new Date(l.start).setHours(0, 0, 0, 0),
        expiry: l.expiry,
        date: new Date(Date.now()),
      });

      await this.$pouch.put(
        {
          _id: this.machineID,
          _rev: this.activationData._rev || undefined,
          type: 'activation',
          active: true,
          machineID: this.machineID,
          license: this.license,
          start: new Date(l.start).setHours(0, 0, 0, 0),
          expiry: l.expiry,
          date: new Date(Date.now()),
          date_updated: Date.now(),
        },
        {},
        'express',
      );

      this.$router.push({
        name: 'login',
      });
      this.$vs.notify({
        position: 'bottom-center',
        color: 'success',
        title: `Thank You`,
        text: 'System successfully activated. You may now login and use this system.',
      });
    },
    savePaymentOption(o) {
      this.$store
        .dispatch('payment_options/addOption', o)
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: `${o.payment_option} Added Successfully`,
            text: 'You have successfully added a new payment option',
          });
        })
        .catch((err) => {
          console.error(err);
          this.$vs.notify({
            color: 'danger',
            title: 'Error',
            text: err,
          });
        });
    },
    async getRecord(type) {
      return await idb.getRecord(type);
    },
    async fill(a, t) {
      let d = (await this.getRecord(t)) || null;
      if (d) {
        for (let i = 0; i < a.length; i++) {
          const e = a[i];
          if (this.hasOwnProperty(e) && d.hasOwnProperty(e)) {
            this[`${e}`] = d[e];
          }
        }
        return d;
      }
      return false;
    },
  },
  async beforeMount() {
    let current_tab = await this.getRecord('current_tab');
    if (current_tab) {
      this.current_tab = current_tab.tab;
    } else {
      this.current_tab = 0;
    }
  },
  async created() {
    let i = await this.getRecord('installation');
    if (i && !i.complete) {
      this.$vs.notify({
        title: 'Continue where you left off',
        text: 'This installation had already started, we saved some data for you so you can continue where you left off.',
        iconPack: 'feather',
        icon: 'icon-check-circle',
        color: 'success',
        time: 13000,
      });
    } else {
      await idb.saveRecord({
        type: 'installation',
        complete: false,
      });
    }

    // await this.fill(['current_tab'], 'current_tab');

    await this.fill(['license'], 'license');
    await this.fill(
      [
        'create_db',
        'link_db',
        'remote_url',
        'remote_username',
        'allow_backups',
        'protocol',
        'remote_port',
      ],
      'database',
    ).then((step1) => {
      this.remote_port = step1.port;
    });

    if (this.current_tab > 0) {
      await this.syncDB();
    }

    await this.fill(['branch_name', 'branch_phone'], 'branch');
    await this.fill(['payment_option', 'payment_option2'], 'payment');
    // let f = await this.fill(
    //   [
    //     'business_name',
    //     'main_phone',
    //     'postal_address',
    //     'postal_city',
    //     'tax_pin',
    //     'vat_reg',
    //     'country',
    //     'currency',
    //   ],
    //   'business',
    // );
    // if (f) {
    //   this.currency = f.currency;
    //   // this.updateCurrency();
    //   // this.country = this.country.countryName;
    // }
    this.publicKey =
      '-----BEGIN PUBLIC KEY-----\n' +
      'MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgGC7ihHUgBC5NdYRF/xxGKGdhn2W\n' +
      '2IXRhaKYZEJvr1s6t+tQMN9yYvhr4j3eZk1zJlMgXOXFr0mOncA3UnLVNQPwsitK\n' +
      'bjh2yWA99lNiW3fAWhlanX2RJPbVP/wuueI5URZXXaxkpH/b/RSswXvjpof+kjED\n' +
      'YRf6+GxCrzJyof/1AgMBAAE=\n' +
      '-----END PUBLIC KEY-----';
    this.getMachineID();
  },
};
</script>

<style lang="scss">
.center-div {
  position: relative;
  width: 100%;
  height: 100%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
